import React from "react";
import SanityBlockContent from "@sanity/block-content-to-react"
import {Link} from "gatsby";
import ExternalLink from "./ExternalLink";

const BlockContent = ({ blocks }) => {

  const serializers = {
    marks: {
      internalLink: ({ mark, children }) => {
        const {reference = {}} = mark
        const href = `/${reference.slug?.current || ''}`
        return <Link to={href}>{children}</Link>
      },
      externalLink: ({mark, children}) => {
        const {href = '', blank = false} = mark
        return <ExternalLink href={href} isBlank={blank}>{children}</ExternalLink>
      },
    }
  }

  if (typeof blocks === 'string') {
    return <>{blocks}</>
  }

  return (
    <SanityBlockContent blocks={blocks} serializers={serializers}/>
  );
}

export default BlockContent
